import React from 'react';
import {
	FormControl,
	FormLabel,
	Select,
	HStack,
	Box,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	Input,
	FormErrorMessage,
} from '@chakra-ui/react';
import { Select as ChakraReactSelect } from 'chakra-react-select';
import { Field } from 'formik';
import { ABTest } from '@repo/alictus-common/types/ab_test';
import { FilterCondition } from '@repo/alictus-common/enums/filter_condition';

import { Countries } from '@repo/alictus-common/enums/remote_config';

const countryList = Countries.map((country) => ({ value: country.code, label: country.name }));

export function Targeting({ abTestModal, updateAbTestModal }: { abTestModal: ABTest; updateAbTestModal: (test: Partial<ABTest>) => void }) {
	return (
		<>
			<Field name="userTargeting">
				{({ field, form }: { field: any; form: any }) => (
					<FormControl mt={4} isInvalid={form.errors.userTargeting && form.touched.userTargeting}>
						<FormLabel>Select User Targeting</FormLabel>
						<Select
							{...field}
							value={abTestModal.userTargeting}
							onChange={(e) => {
								form.setFieldValue('userTargeting', parseInt(e.target.value));
								updateAbTestModal({ userTargeting: parseInt(e.target.value) });
							}}
						>
							<option value={0}>All</option>
							<option value={1}>Only New</option>
							<option value={2}>Only Existing</option>
						</Select>
						<FormErrorMessage>{form.errors.userTargeting}</FormErrorMessage>
					</FormControl>
				)}
			</Field>

			<Field name="country">
				{({ field, form }: { field: any; form: any }) => (
					<FormControl mt={4} paddingTop={'5px'} isInvalid={form.errors.country}>
						<FormLabel>Select Country</FormLabel>
						<HStack spacing={4} paddingTop={'10px'}>
							<Select
								{...field}
								value={abTestModal.countryFilter}
								width={'200px'}
								onChange={(e: any) => {
									form.setFieldValue('countryFilter', parseInt(e.target.value));
									updateAbTestModal({ countryFilter: parseInt(e.target.value) });
								}}
							>
								<option value={0}>Select Filter</option>
								<option value={7}>Include</option>
								<option value={8}>Exclude</option>
							</Select>
							<Box flex={1} width={'660px'}>
								<ChakraReactSelect
									{...field}
									options={countryList}
									value={abTestModal.country}
									isMulti
									onChange={(e: any) => {
										if (e) {
											form.setFieldValue('country', e as { value: string; label: string }[]);
											updateAbTestModal({ country: e as { value: string; label: string }[] });
										}
										//field.onChange(e);
									}}
								/>
							</Box>
						</HStack>
						<FormErrorMessage>{form.errors.country}</FormErrorMessage>
					</FormControl>
				)}
			</Field>

			<Field name="version">
				{({ field, form }: { field: any; form: any }) => (
					<FormControl mt={4} paddingTop={'5px'} isInvalid={form.errors.version}>
						<FormLabel>Select Version</FormLabel>
						<HStack spacing={4} paddingTop={'10px'}>
							<Select
								value={abTestModal.versionFilter}
								width={'200px'}
								onChange={(e: any) => {
									if (e && e.target.value) {
										form.setFieldValue('versionFilter', parseInt(e.target.value));
										updateAbTestModal({ versionFilter: parseInt(e.target.value) });
										//field.onChange(e);
									}
								}}
							>
								{Object.keys(FilterCondition)
									.filter((key) => isNaN(Number(key))) // Only include string keys
									.map((key) => (
										<option
											key={FilterCondition[key as keyof typeof FilterCondition]}
											value={FilterCondition[key as keyof typeof FilterCondition]}
										>
											{key}
										</option>
									))}
							</Select>
							<Box flex={1}>
								<Input
									{...field}
									value={abTestModal.version}
									onChange={(e) => {
										if (e) {
											form.setFieldValue('version', e.target.value);
											updateAbTestModal({ version: e.target.value });
											//field.onChange(e);
										}
									}}
									placeholder="Filter Value"
								/>
							</Box>
						</HStack>
						<FormErrorMessage>{form.errors.version}</FormErrorMessage>
					</FormControl>
				)}
			</Field>

			<Field name="buildNumber">
				{({ field, form }: { field: any; form: any }) => (
					<FormControl mt={4} paddingTop={'5px'} isInvalid={form.errors.buildNumber}>
						<FormLabel>Select Build Number</FormLabel>
						<HStack spacing={4} paddingTop={'10px'}>
							<Select
								value={abTestModal.buildNumberFilter.toString() || 0}
								width={'200px'}
								onChange={(e: any) => {
									if (e) {
										form.setFieldValue('buildNumberFilter', parseInt(e.target.value));
										updateAbTestModal({ buildNumberFilter: parseInt(e.target.value) });
									}
								}}
							>
								{Object.keys(FilterCondition)
									.filter((key) => isNaN(Number(key))) // Only include string keys
									.map((key) => (
										<option
											key={FilterCondition[key as keyof typeof FilterCondition]}
											value={FilterCondition[key as keyof typeof FilterCondition]}
										>
											{key}
										</option>
									))}
							</Select>
							<Box flex={1}>
								<NumberInput
									{...field}
									value={abTestModal.buildNumber.toString() || 0}
									onChange={(e: any) => {
										if (e) {
											form.setFieldValue('buildNumber', parseInt(e));
											updateAbTestModal({ buildNumber: parseInt(e) });
											//field.onChange(e);
										}
									}}
								>
									<NumberInputField />
									<NumberInputStepper>
										<NumberIncrementStepper />
										<NumberDecrementStepper />
									</NumberInputStepper>
								</NumberInput>
							</Box>
						</HStack>
						<FormErrorMessage>{form.errors.buildNumber}</FormErrorMessage>
					</FormControl>
				)}
			</Field>
		</>
	);
}

export default Targeting;
