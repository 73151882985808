import React, { useState } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, TableContainer, Button, Box, Text, Flex, Textarea, Icon, Tooltip } from '@chakra-ui/react';
import { SearchIcon, ViewIcon } from '@chakra-ui/icons';
import { FaTrashAlt } from 'react-icons/fa';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { CgCloseO } from 'react-icons/cg';
import { RemoteConfigValue } from '@repo/alictus-common/types';
import { ViewFilter } from '../view_filter';
import { GlobalStore } from '../../store';
import { RemoteConfigTypeToString, formatTimestamp } from '../../utility';
import { FilterToString } from '../utils/filter_utils';
import { toggleRCValueStatus } from '../utils/remote_config_utils';
import { PlayerSegment } from '@repo/alictus-common/types/segment';

interface RemoteConfigTableProps {
	globalData: GlobalStore;
	viewFilter: ViewFilter;
	editRCVariableDisclosure: any;
	deleteRCKeyDisclosure: any;
	setDeleteRCKeyData: (data: any) => void;
	multiFunction: (disc: { onOpen: () => void }, remoteConfigValue: RemoteConfigValue) => void;
	segmentationData: PlayerSegment[];
}

export function RemoteConfigTable({
	globalData,
	viewFilter,
	editRCVariableDisclosure,
	deleteRCKeyDisclosure,
	setDeleteRCKeyData,
	multiFunction,
	segmentationData,
}: RemoteConfigTableProps) {
	const [sortField, setSortField] = useState<'key' | 'lastUpdated' | 'type' | 'isTest' | 'isPersonal' | 'isActive'>('key');
	const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

	const handleSort = (field: 'key' | 'lastUpdated' | 'type' | 'isTest' | 'isPersonal' | 'isActive') => {
		if (sortField === field) {
			setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		} else {
			setSortField(field);
			setSortDirection('asc');
		}
	};

	const sortedData = [...(globalData.remoteConfigValues || [])].sort((a, b) => {
		let result = 0;
		if (sortField === 'key') {
			result = a.key.localeCompare(b.key);
		} else if (sortField === 'lastUpdated') {
			result = new Date(a.lastUpdated).getTime() - new Date(b.lastUpdated).getTime();
		} else if (sortField === 'type') {
			result = RemoteConfigTypeToString(a.type).localeCompare(RemoteConfigTypeToString(b.type));
		} else if (sortField === 'isTest' || sortField === 'isPersonal' || sortField === 'isActive') {
			result = (a[sortField] ? 1 : 0) - (b[sortField] ? 1 : 0);
		}
		return sortDirection === 'asc' ? result : -result;
	});

	return (
		<TableContainer>
			<Table variant="simple" size="md" border={'0px'}>
				<Thead sx={{ th: { textAlign: 'center', verticalAlign: 'middle' } }}>
					<Tr>
						<Th width="150px" cursor="pointer">
							<Flex align="center" gap="15px">
								<Tooltip label="Coming Soon" placement="top">
									<Icon
										as={SearchIcon}
										boxSize={4}
										cursor="pointer"
										onClick={(e) => {
											e.stopPropagation();
											// This will be implemented in the future
											console.log('Search icon clicked');
										}}
									/>
								</Tooltip>
								<span onClick={() => handleSort('key')}>Name {sortField === 'key' && (sortDirection === 'asc' ? '↑' : '↓')}</span>
							</Flex>
						</Th>
						<Th width="200px">Description</Th>
						<Th width="200px">Value</Th>
						<Th width="100px" onClick={() => handleSort('type')} cursor="pointer">
							Type {sortField === 'type' && (sortDirection === 'asc' ? '↑' : '↓')}
						</Th>
						<Th width="250px">Filter</Th>
						<Th width="200px" onClick={() => handleSort('lastUpdated')} cursor="pointer">
							Latest Update {sortField === 'lastUpdated' && (sortDirection === 'asc' ? '↑' : '↓')}
						</Th>
						<Th width="150px" onClick={() => handleSort('isTest')} cursor="pointer">
							Production {sortField === 'isTest' && (sortDirection === 'asc' ? '↑' : '↓')}
						</Th>
						<Th width="150px" onClick={() => handleSort('isPersonal')} cursor="pointer">
							Public {sortField === 'isPersonal' && (sortDirection === 'asc' ? '↑' : '↓')}
						</Th>
						<Th width="150px" onClick={() => handleSort('isActive')} cursor="pointer">
							Status {sortField === 'isActive' && (sortDirection === 'asc' ? '↑' : '↓')}
						</Th>
						<Th width="50px">View</Th>
						<Th width="50px">Delete</Th>
					</Tr>
				</Thead>
				<Tbody>
					{sortedData.map(
						(field, i) =>
							viewFilter.canDisplay(field) && (
								<TableRow
									key={i}
									field={field}
									globalData={globalData}
									editRCVariableDisclosure={editRCVariableDisclosure}
									deleteRCKeyDisclosure={deleteRCKeyDisclosure}
									setDeleteRCKeyData={setDeleteRCKeyData}
									multiFunction={multiFunction}
									segmentationData={segmentationData}
								/>
							),
					)}
				</Tbody>
			</Table>
		</TableContainer>
	);
}

interface TableRowProps {
	field: RemoteConfigValue;
	globalData: GlobalStore;
	editRCVariableDisclosure: any;
	deleteRCKeyDisclosure: any;
	setDeleteRCKeyData: (data: any) => void;
	multiFunction: (disc: { onOpen: () => void }, remoteConfigValue: RemoteConfigValue) => void;
	segmentationData: PlayerSegment[];
}

function TableRow({
	field,
	globalData,
	editRCVariableDisclosure,
	deleteRCKeyDisclosure,
	setDeleteRCKeyData,
	multiFunction,
	segmentationData,
}: TableRowProps) {
	return (
		<Tr>
			<Td
				textAlign="left"
				verticalAlign="middle"
				width="180px"
				maxW={'180px'}
				overflow="hidden"
				textOverflow="ellipsis"
				whiteSpace="nowrap"
				_hover={{ overflow: 'visible', whiteSpace: 'normal' }}
			>
				{field.key}
			</Td>
			<Td
				textAlign="center"
				verticalAlign="middle"
				width={'180px'}
				maxW={'180px'}
				overflow="hidden"
				textOverflow="ellipsis"
				whiteSpace="nowrap"
				position="relative"
				_hover={{ overflow: 'visible', whiteSpace: 'normal' }}
			>
				{field.description}
			</Td>
			<Td textAlign="left" verticalAlign="middle" width="180px" maxW="180px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
				<Text maxW="180px" isTruncated>
					{String(field.value)}
				</Text>
			</Td>
			<Td textAlign="center" verticalAlign="middle" width="100px">
				{RemoteConfigTypeToString(field.type)}
			</Td>
			<Td textAlign="center" verticalAlign="middle" width="250px">
				{field.filterOperations.length > 0 && (
					<Textarea
						minW="250px"
						fontSize={'sm'}
						border={'0px'}
						value={FilterToString(field.filterOperations, segmentationData)}
						isReadOnly
					/>
				)}
			</Td>
			<Td textAlign="center" verticalAlign="middle" width="200px">
				{formatTimestamp(field.lastUpdated)}
			</Td>
			<Td textAlign="center" verticalAlign="middle" width="150px">
				{showCheckMark(!field.isTest)}
			</Td>
			<Td textAlign="center" verticalAlign="middle" width="150px">
				{showCheckMark(!field.isPersonal)}
			</Td>
			<Td textAlign="center" verticalAlign="middle" width="150px">
				<Button
					onClick={() => toggleRCValueStatus(globalData, field.id)}
					leftIcon={
						<Icon
							as={field.isActive ? BsFillCheckCircleFill : CgCloseO}
							color={field.isActive ? 'green.200' : 'red.200'}
							_hover={{ color: 'gray.300' }}
						/>
					}
					variant="simple"
				/>
			</Td>
			<Td textAlign="center" verticalAlign="middle" width="50px">
				<ViewButton onOpen={() => multiFunction(editRCVariableDisclosure, field)} />
			</Td>
			<Td textAlign="center" verticalAlign="middle" width="50px">
				<Button
					onClick={() => {
						setDeleteRCKeyData(field);
						deleteRCKeyDisclosure.onOpen();
					}}
					leftIcon={<Icon as={FaTrashAlt} color={'gray.200'} _hover={{ color: 'gray.300' }} />}
					variant="simple"
				/>
			</Td>
		</Tr>
	);
}

function showCheckMark(isActive: boolean) {
	return (
		<Flex justify="center" align="center" width="100%" height="100%">
			<Icon as={isActive ? BsFillCheckCircleFill : CgCloseO} color={isActive ? 'green.200' : 'red.200'} _hover={{ color: 'gray.300' }} />
		</Flex>
	);
}

function ViewButton({ onOpen }: { onOpen: () => void }) {
	return (
		<Button
			onClick={onOpen}
			aria-label="View"
			leftIcon={<ViewIcon color={'gray.500'} _hover={{ color: 'gray.300' }} />}
			backgroundColor={'transparent'}
			_hover={{ bg: 'transparent', color: 'white' }}
		/>
	);
}
