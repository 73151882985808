import React, { useState, useEffect } from 'react';
import { Box, HStack, Checkbox, FormControl, Select, Flex, Wrap, WrapItem } from '@chakra-ui/react';
import { ViewFilter } from '../view_filter';
import { PlusButton } from './plus_button';
import { useUniqueValues } from '../hooks/use_unique_values';
import globalStore from '../../store';
import { PlayerSegment } from '@repo/alictus-common/types/segment';

interface RemoteConfigHeaderProps {
	addVariableDisclosure: any;
	viewFilter: ViewFilter;
	segmentationData: PlayerSegment[];
	setViewFilter: React.Dispatch<React.SetStateAction<ViewFilter>>;
}

export function RemoteConfigHeader({ addVariableDisclosure, viewFilter, segmentationData, setViewFilter }: RemoteConfigHeaderProps) {
	let globalData = globalStore();
	const [filteredCountry, setFilteredCountry] = useState('');
	const [filteredVersion, setFilteredVersion] = useState('');
	const [filteredPlatform, setFilteredPlatform] = useState('');
	const [filteredViewSegmentation, setViewSegmentation] = useState('');
	const [filteredEnvironment, setFilteredEnvironment] = useState<string>('Select Environment');
	const [filteredViewPrivate, setViewPrivate] = useState<string>('Select Visibility');

	const { availableVersions, availableCountries, availableSegmentations } = useUniqueValues(
		globalData.remoteConfigValues,
		segmentationData,
	);

	useEffect(() => {
		setViewFilter((prevViewFilter) => {
			const updatedFilter = new ViewFilter();
			Object.assign(updatedFilter, prevViewFilter);
			updatedFilter.ViewVersion = filteredVersion !== 'Select Version' ? filteredVersion : null;
			updatedFilter.ViewPlatform = filteredPlatform !== 'Select Platform' ? filteredPlatform.toLowerCase() : null;
			updatedFilter.ViewIsTestEnvironment = filteredEnvironment === 'Production' ? false : filteredEnvironment === 'Test' ? true : null;
			updatedFilter.ViewPrivate = filteredViewPrivate === 'Public' ? false : filteredViewPrivate === 'Private' ? true : null;
			updatedFilter.ViewCountry = filteredCountry !== 'Select Country' ? filteredCountry.toLowerCase() : null;
			updatedFilter.ViewSegmentation = filteredViewSegmentation !== 'Select Segmentation' ? filteredViewSegmentation : null;
			return updatedFilter;
		});
	}, [
		filteredVersion,
		filteredPlatform,
		filteredEnvironment,
		filteredViewPrivate,
		filteredCountry,
		filteredViewSegmentation,
		setViewFilter,
	]);

	return (
		<HStack
			width="100%"
			height="60px"
			bg="gray.800"
			paddingX="10px"
			spacing="7px"
			overflowX="auto" // Allows scrolling if content overflows
			whiteSpace="nowrap" // Prevents wrapping
			alignItems="center"
		>
			<Box minWidth="100px">
				<strong>Main Config</strong>
			</Box>

			<PlusButton onOpen={addVariableDisclosure.onOpen} />

			<Checkbox
				flexShrink={0}
				minWidth="200px"
				onChange={() =>
					setViewFilter((prevViewFilter) => {
						const updatedFilter = new ViewFilter();
						Object.assign(updatedFilter, prevViewFilter);
						updatedFilter.ViewOnlyActive = !viewFilter.ViewOnlyActive;
						return updatedFilter;
					})
				}
			>
				View Disabled Keys
			</Checkbox>

			<FilterSelect value={filteredPlatform} onChange={setFilteredPlatform} options={['Select Platform', 'iOS', 'Android']} />

			<ExclusiveCheckbox
				onChange={() =>
					setViewFilter((prev) => {
						const updatedFilter = new ViewFilter();
						return Object.assign(updatedFilter, prev, { ExclusivePlatform: !prev.ExclusivePlatform });
					})
				}
			/>

			<FilterSelect value={filteredVersion} onChange={setFilteredVersion} options={availableVersions} />

			<ExclusiveCheckbox
				onChange={() =>
					setViewFilter((prev) => {
						const updatedFilter = new ViewFilter();
						return Object.assign(updatedFilter, prev, { ExclusiveVersion: !prev.ExclusiveVersion });
					})
				}
			/>

			<FilterSelect value={filteredCountry} onChange={setFilteredCountry} options={availableCountries} />

			<ExclusiveCheckbox
				onChange={() =>
					setViewFilter((prev) => {
						const updatedFilter = new ViewFilter();
						return Object.assign(updatedFilter, prev, { ExclusiveCountry: !prev.ExclusiveCountry });
					})
				}
			/>

			<FilterSelect value={filteredEnvironment} onChange={setFilteredEnvironment} options={['Select Environment', 'Production', 'Test']} />

			<FilterSelect value={filteredViewPrivate} onChange={setViewPrivate} options={['Select Visibility', 'Private', 'Public']} />

			<FilterSelect value={filteredViewSegmentation} onChange={setViewSegmentation} options={availableSegmentations} />
		</HStack>
	);
}

interface FilterSelectProps {
	value: string;
	onChange: (value: string) => void;
	options: string[];
}

function FilterSelect({ value, onChange, options }: FilterSelectProps) {
	return (
		<FormControl flex="1" minW={'200px'}>
			<Select value={value} onChange={(e) => onChange(e.target.value)}>
				{options.map((option) => (
					<option key={option} value={option}>
						{option}
					</option>
				))}
			</Select>
		</FormControl>
	);
}

function ExclusiveCheckbox({ onChange }: { onChange: () => void }) {
	return (
		<Checkbox paddingRight={'15px'} onChange={onChange}>
			Excl.
		</Checkbox>
	);
}
