import {
	Button,
	Box,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	Grid,
	GridItem,
	HStack,
	VStack,
	Flex,
	IconButton,
	useToast,
} from '@chakra-ui/react';
import { Icon, PlusSquareIcon, ViewIcon } from '@chakra-ui/icons';
import { useDisclosure } from '@chakra-ui/hooks';
import React, { useState, useEffect, useRef } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import axios from 'axios';
import config from '../config';
import globalStore, { GlobalStore } from '../store';
import { formatTimestamp } from '../utility';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { CgCloseO } from 'react-icons/cg';
import { PlayerSegment } from '@repo/alictus-common/types/segment';
import SegmentModal from './segment_modal';
import DeleteSegmentModal from './delete_segment_modal';

function SegmentationView2() {
	let globalData = globalStore();
	const createSegment = useDisclosure();
	const editSegment = useDisclosure();
	const [selectedSegment, setSelectedSegment] = useState<PlayerSegment>();
	const [playerSegments, setPlayerSegments] = useState<PlayerSegment[]>([]);
	const deleteSegmentDisclosure = useDisclosure();
	const [segmentToDelete, setSegmentToDelete] = useState<PlayerSegment>();
	const hasFetchedData = useRef(false);
	const isMounted = useRef(true);
	const toast = useToast();

	useEffect(() => {
		if (!hasFetchedData.current) {
			console.log('Fetching segmentation data');
			hasFetchedData.current = true;
			fetchSegmentationData(globalData, setPlayerSegments, isMounted);
		}
		return () => {
			isMounted.current = true; // This will stop the polling when the component unmounts
		};
	}, []);

	const multiFunction = (disc: { onOpen: () => void }, segmentInstance: PlayerSegment) => {
		//console.log('Selected Segment: ', segmentInstance);
		setSelectedSegment(segmentInstance);
		disc.onOpen();
	};

	async function fetchSegmentationData(
		globalData: GlobalStore,
		setPlayerSegments: React.Dispatch<React.SetStateAction<PlayerSegment[]>>,
		isMounted: React.RefObject<boolean>,
	) {
		let lastUpdated = 0; // implement this later
		try {
			while (isMounted.current) {
				//console.log("Last Updated: " + lastUpdated);
				let bearerToken = 'Bearer ' + globalData.JWTToken;
				let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + globalData.gameId + '/segment';
				const response = await axios.get(url, { headers: { Authorization: bearerToken } });
				let playerSegments = response.data as PlayerSegment[];

				//console.log('Player Segments: ');
				//console.log(playerSegments.map((segment) => segment));
				if (playerSegments) {
					//console.log('Updated Segments : ', playerSegments);
					setPlayerSegments(playerSegments);
					//lastUpdated = getLatestUpdate(incomingRemoteConfigValues);
				}

				// Add a delay to prevent infinite loop
				await new Promise((resolve) => setTimeout(resolve, 1000));
			}
		} catch (error) {
			console.error('Error fetching remote config data:', error);
		}
	}

	async function toggleSegmentEnvironment(segmentInstance: PlayerSegment) {
		try {
			let playerSegment: PlayerSegment = { ...segmentInstance, isTest: !segmentInstance.isTest };
			console.log('Updating segment status: ');
			console.log(playerSegment);
			let url = config.CLOUDFLARE_LOCAL_ENDPOINT + '/game/' + globalData.gameId + `/segment/${segmentInstance?.id}`;
			await axios.put(url, playerSegment, { headers: { Authorization: 'Bearer ' + globalData.JWTToken } }).then((response) => {
				console.log(response);
			});
		} catch (error: any) {
			console.error(error);
			toast({
				title: 'Error Updating Segment Status',
				description: error.response?.data,
				status: 'error',
				duration: 10000,
				isClosable: true,
				position: 'top',
			});
		}
	}

	function showCheckMark(isActive: boolean, onClick: () => void) {
		return (
			<Flex justify="center" align="center" width="100%" height="100%">
				{isActive ? (
					<Icon as={BsFillCheckCircleFill} color={'green.200'} _hover={{ color: 'gray.300' }} onClick={onClick} />
				) : (
					<Icon as={CgCloseO} color={'red.200'} _hover={{ color: 'gray.300' }} onClick={onClick} />
				)}
			</Flex>
		);
	}

	function ViewButton({ onOpen }: { onOpen: () => void }) {
		return (
			<IconButton
				onClick={onOpen}
				aria-label="Add"
				icon={<ViewIcon color={'gray.500'} _hover={{ color: 'gray.300' }} />}
				backgroundColor={'transparent'}
				_hover={{ bg: 'transparent', color: 'white' }}
			/>
		);
	}

	function PlusButton({ paddingTop, onOpen }: { paddingTop?: string; onOpen: () => void }) {
		return (
			<IconButton
				onClick={onOpen}
				paddingTop={paddingTop}
				aria-label="Add"
				icon={<PlusSquareIcon color={'gray.500'} _hover={{ color: 'gray.300' }} />}
				backgroundColor={'transparent'}
				_hover={{ bg: 'transparent', color: 'white' }}
			/>
		);
	}

	const deleteSegmentModalOpen = (disc: { onOpen: () => void }, segmentInstance: PlayerSegment) => {
		setSegmentToDelete(segmentInstance);
		disc.onOpen();
	};

	return (
		<Box border={'2px'} borderRadius={'5px'} borderColor={'gray.600'}>
			{deleteSegmentDisclosure.isOpen && segmentToDelete !== undefined && (
				<DeleteSegmentModal segmentInstance={segmentToDelete} disclosure={deleteSegmentDisclosure} />
			)}

			{editSegment.isOpen && (
				<SegmentModal title={'Edit Segment'} isOpen={editSegment.isOpen} onClose={editSegment.onClose} segmentInstance={selectedSegment} />
			)}

			{createSegment.isOpen && <SegmentModal title={'Create Segment'} isOpen={createSegment.isOpen} onClose={createSegment.onClose} />}

			<VStack spacing={1} align="stretch">
				<HStack flex={1} align={'center'}>
					<Box paddingLeft={'10px'}>
						<strong>User Segments</strong>
					</Box>
					<PlusButton onOpen={createSegment.onOpen} paddingTop={'0px'} />
				</HStack>
				<TableContainer>
					<Table variant="simple" size="md" border={'0px'}>
						<Thead>
							<Tr>
								<Th width="200px" maxW="200px">
									Name
								</Th>
								<Th width="250px" maxW="250px">
									Description
								</Th>
								<Th width="200px" maxW="200px">
									Creation Date
								</Th>
								<Th width="150px" maxW="150px">
									Last Update
								</Th>
								<Th width="150px" maxW="150px">
									<Flex justify="center" align="center" width="100%" height="100%">
										Production
									</Flex>
								</Th>
								{/*<Th width="50px"><Flex justify="center" align="center" width="100%" height="100%">Status</Flex></Th>*/}
								<Th width="50px" maxW="150px">
									<Flex justify="center" align="center" width="100%" height="100%">
										View
									</Flex>
								</Th>
								<Th width="50px" maxW="150px">
									<Flex justify="center" align="center" width="100%" height="100%">
										Delete
									</Flex>
								</Th>
							</Tr>
						</Thead>
						<Tbody>
							{playerSegments
								.sort((a: PlayerSegment, b: PlayerSegment) => a.name.localeCompare(b.name))
								.map((field: PlayerSegment, i: number) => (
									<Tr>
										<Td
											width="200px"
											maxW="200px"
											overflow="hidden"
											textOverflow="ellipsis"
											whiteSpace="nowrap"
											position="relative"
											_hover={{ overflow: 'visible', whiteSpace: 'normal' }}
										>
											{field.name}
										</Td>
										<Td
											width="200px"
											maxW="250px"
											overflow="hidden"
											textOverflow="ellipsis"
											whiteSpace="nowrap"
											position="relative"
											_hover={{ overflow: 'visible', whiteSpace: 'normal' }}
										>
											{field.description}
										</Td>
										<Td width="200px" maxW="200px">
											{formatTimestamp(field.creationDate)}
										</Td>
										<Td width="150px" maxW="150px">
											{formatTimestamp(field.lastUpdated)}
										</Td>
										<Td width="50px" maxW="150px">
											{showCheckMark(!field.isTest, () => toggleSegmentEnvironment(field))}
										</Td>
										{/*                                <Td width="50px"><Flex justify="center" align="center" width="100%" height="100%">
                                    <Icon as={BsFillCheckCircleFill} color={'green.200'} _hover={{ color: 'gray.300' }} /></Flex>
                                </Td>*/}
										<Td>
											<Flex justify="center" align="center" width="100%" height="100%">
												<ViewButton onOpen={() => multiFunction(editSegment, field)} />
											</Flex>
										</Td>
										<Td width="50px">
											<Flex justify="center" align="center" width="100%" height="100%">
												<Button
													onClick={() => {
														deleteSegmentModalOpen(deleteSegmentDisclosure, field);
													}}
													leftIcon={<Icon as={FaTrashAlt} color={'gray.200'} _hover={{ color: 'gray.300' }} />}
													variant="simple"
												/>
											</Flex>
										</Td>
									</Tr>
								))}
						</Tbody>
					</Table>
				</TableContainer>
			</VStack>
		</Box>
	);
}

function SegmentationView() {
	return (
		<Grid
			height={'1px'}
			width={'100%'}
			templateRows="repeat(4, 1fr)"
			templateColumns="repeat(5, 1fr)"
			gap={2}
			paddingLeft={'12px'}
			paddingRight={'10px'}
		>
			<GridItem colSpan={6} padding={'10px'}></GridItem>
			<GridItem paddingLeft={'5px'} paddingRight={'5px'} colSpan={6}>
				<SegmentationView2 />
			</GridItem>
		</Grid>
	);
}
export default SegmentationView;
