import React, { useEffect, useState } from 'react';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useToast,
	VStack,
} from '@chakra-ui/react';

import { Form, Formik, FormikValues } from 'formik';

import BasicInfo from './components/BasicInfo';
import Targeting from './components/Targeting';
import Distribution from './components/Distribution';
import Variants from './components/Variants';
import globalStore, { GlobalStore } from '../store';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { createExperimentRequest, updateExperiment } from './abTestApi';

import { ABTestState } from '@repo/alictus-common/enums/ab_test_state';
import { Platform } from '@repo/alictus-common/enums/platform';
import { UserTargeting } from '@repo/alictus-common/enums/user_targeting';
import { ABTest } from '@repo/alictus-common/types/ab_test';
import abTestValidationSchema from '@repo/alictus-common/validation/ab_test_schema';

export function ABTestModal({
	title,
	isOpen,
	onClose,
	abTestToUpdate,
}: {
	title: string;
	isOpen: boolean;
	onClose: () => void;
	abTestToUpdate?: ABTest;
}) {
	let store = globalStore();
	const toast = useToast();
	const [abTestModal, setABTestModal] = useState<ABTest>(createDefaultABTest());

	useEffect(() => {
		if (abTestToUpdate) {
			setABTestModal(abTestToUpdate);
		} else if (abTestToUpdate === undefined) {
			setABTestModal(createDefaultABTest());
		}
	}, [abTestToUpdate]);

	function updateABTest(abTest: Partial<ABTest>) {
		setABTestModal({ ...abTestModal, ...abTest });
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent maxW="950px" border={'1px'} borderColor={'gray.700'}>
				<ModalHeader>{title}</ModalHeader>
				<Formik
					initialValues={(abTestToUpdate as FormikValues) || createDefaultABTest()}
					validationSchema={toFormikValidationSchema(abTestValidationSchema)}
					onSubmit={async (values, actions) => {
						if (abTestModal === null) {
							await handleCreateExperiment(store, abTestModal, toast, onClose);
						}

						if (abTestModal !== null) {
							await handleUpdateExperiment(store, abTestModal!, toast, onClose);
						}
						actions.setSubmitting(false);
					}}
				>
					{(props) => (
						<Form>
							<ModalBody>
								<VStack spacing={4}>
									<Accordion border={'1px'} borderColor={'gray.700'} width={'100%'}>
										<AccordionItem>
											<h2>
												<AccordionButton backgroundColor={'gray.600'}>
													<Box as="span" flex="1" textAlign="left">
														Basics
													</Box>
													<AccordionIcon />
												</AccordionButton>
											</h2>
											<AccordionPanel pb={4}>{<BasicInfo abTestModal={abTestModal!} updateAbTestModal={updateABTest} />}</AccordionPanel>
										</AccordionItem>

										<AccordionItem>
											<h2>
												<AccordionButton backgroundColor={'gray.600'}>
													<Box as="span" flex="1" textAlign="left">
														Targeting
													</Box>
													<AccordionIcon />
												</AccordionButton>
											</h2>
											<AccordionPanel pb={4}>{<Targeting abTestModal={abTestModal!} updateAbTestModal={updateABTest} />}</AccordionPanel>
										</AccordionItem>

										<AccordionItem>
											<h2>
												<AccordionButton backgroundColor={'gray.600'}>
													<Box as="span" flex="1" textAlign="left">
														Distribution
													</Box>
													<AccordionIcon />
												</AccordionButton>
											</h2>
											<AccordionPanel pb={4}>{<Distribution abTestModal={abTestModal!} updateAbTestModal={updateABTest} />}</AccordionPanel>
										</AccordionItem>

										<AccordionItem>
											<h2>
												<AccordionButton backgroundColor={'gray.600'}>
													<Box as="span" flex="1" textAlign="left">
														Variants
													</Box>
													<AccordionIcon />
												</AccordionButton>
											</h2>
											<AccordionPanel pb={4}>
												<Variants abTestModal={abTestModal!} updateAbTestModal={updateABTest} rcValues={store.remoteConfigValues} />
											</AccordionPanel>
										</AccordionItem>
									</Accordion>
								</VStack>
							</ModalBody>
							<ModalFooter>
								<Button variant="ghost" mr={3} onClick={onClose}>
									Close
								</Button>
								<Button colorScheme="blue" mr={3} isLoading={props.isSubmitting} isDisabled={!props.isValid || !props.dirty} type="submit">
									{abTestToUpdate === undefined ? 'Create Experiment' : 'Update Experiment'}
								</Button>
							</ModalFooter>
						</Form>
					)}
				</Formik>
			</ModalContent>
		</Modal>
	);
}

const handleCreateExperiment = async (store: GlobalStore, abTest: ABTest, toast: any, onClose: () => void) => {
	try {
		//await abTestValidationSchema.validate(values, { abortEarly: false });
		//const payload = createAbTestPayload({ ...values, responseData });

		if (!store.JWTToken || !store.gameId) {
			throw new Error('Invalid user token or game id');
		}

		await createExperimentRequest(store.JWTToken, store.gameId, abTest);
		onClose();
		toast({
			title: 'Experiment created.',
			description: 'Your A/B test experiment has been successfully created.',
			status: 'success',
			duration: 5000,
			isClosable: true,
		});
	} catch (error) {
		console.error('Error creating experiment:', error);
		toast({
			title: 'Error creating experiment.',
			description: error instanceof Error ? error.message : String(error),
			status: 'error',
			duration: 5000,
			isClosable: true,
		});
	}
};

const handleUpdateExperiment = async (store: GlobalStore, abTest: ABTest, toast: any, onClose: () => void) => {
	try {
		//await abTestValidationSchema.validate(values, { abortEarly: false });
		//const payload = createAbTestPayload({ ...values, responseData });
		if (!store.JWTToken || !store.gameId) {
			throw new Error('Invalid user token or game id');
		}

		await updateExperiment(store.JWTToken, store.gameId, abTest);
		onClose();
		toast({
			title: 'Experiment updated.',
			description: 'Your A/B test experiment has been successfully updated.',
			status: 'success',
			duration: 5000,
			isClosable: true,
		});
	} catch (error) {
		console.error('Error updating experiment:', error);
		toast({
			title: 'Error updating experiment.',
			description: error instanceof Error ? error.message : String(error),
			status: 'error',
			duration: 5000,
			isClosable: true,
		});
	}
};

function createDefaultABTest(): ABTest {
	return {
		id: 0,
		uid: '',
		name: '',
		description: '',
		identifier: '',
		freezeRemoteConfig: false,
		isTargeted: false,
		dailyUserLimit: 0,
		totalMaximumUserCount: 0,
		creationDate: Date.now(),
		activationDate: null,
		lastUpdated: Date.now(),
		variants: [
			{
				uuid: '',
				name: 'Base Variant',
				values: [],
			},
			{
				uuid: '',
				name: 'Variant 1',
				values: [],
			},
		],
		state: ABTestState.WaitingToStart,
		allowMultipleABTests: true,
		activationEventName: null,
		filterOperations: [],
		platform: Platform.All,
		userTargeting: UserTargeting.All,
		buildNumber: 0,
		buildNumberFilter: 0,
		version: null,
		versionFilter: 0,
		country: null,
		countryFilter: 0,
		createdBy: 0,
		updatedBy: 0,
		endDate: null,
		isDeleted: false,
		isOverWritten: false,
	};
}

export default ABTestModal;
