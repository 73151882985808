import React from 'react';
import {
	Button,
	FormControl,
	FormErrorMessage,
	HStack,
	Input,
	InputGroup,
	InputLeftAddon,
	InputRightAddon,
	Select,
	Stack,
	Textarea,
	useToast,
	VStack,
} from '@chakra-ui/react';
import { LuTestTube2 } from 'react-icons/lu';
import { FaTrashAlt } from 'react-icons/fa';
import { Field, FieldArray } from 'formik';
import { ABTestVariant } from '@repo/alictus-common/types/ab_test';
import { RemoteConfigValue } from '@repo/alictus-common/types/remote_config';
import { Platform } from '@repo/alictus-common/enums/platform';
import { RCType } from '@repo/alictus-common/enums/remote_config';
import { ABTest } from '@repo/alictus-common/types/ab_test';

export function Variants({
	abTestModal,
	updateAbTestModal,
	rcValues,
}: {
	abTestModal: ABTest;
	updateAbTestModal: (test: Partial<ABTest>) => void;
	rcValues: Array<RemoteConfigValue>;
}) {
	//const { abTestModal, updateAbTestModal, updateAbTestModalVariant, deleteABTestModalVariant, updateAbTestModalVariantRCValue } = dataStore;
	const toast = useToast();

	if (!abTestModal) {
		return null;
	}

	const addVariableToVariants = (variableKey: string) => {
		if (abTestModal === null) {
			console.error('abTestModal is null');
			return;
		}
		const newValue: RemoteConfigValue = {
			abTestUid: '',
			description: '',
			filterOperations: [],
			id: 0,
			isActive: true,
			isDeleted: false,
			isPersonal: false,
			isOverWritten: false,
			isTest: false,
			lastUpdated: 0,
			platform: Platform.All,
			type: RCType.Integer,
			userId: 0,
			variantId: null,
			key: variableKey,
			uid: '',
			value: '',
		};

		for (let i = 0; i < abTestModal.variants.length; i++) {
			const variantValues = abTestModal.variants[i].values;
			for (let j = 0; j < variantValues.length; j++) {
				if (variableKey === variantValues[j].key) {
					console.log('Variable already exists');
					return;
				}
			}
		}

		for (let i = 0; i < abTestModal.variants.length; i++) {
			abTestModal.variants[i].values.push(newValue);
		}

		const newVariants = [...abTestModal.variants];
		updateAbTestModal({ variants: newVariants });
	};

	const deleteVariableFromVariants = (variableKey: string) => {
		if (abTestModal === null) {
			console.error('abTestModal is null');
			return;
		}

		for (let i = 0; i < abTestModal.variants.length; i++) {
			const variantValues = abTestModal.variants[i].values;
			abTestModal.variants[i].values = variantValues.filter((value) => value.key !== variableKey);
		}

		const newVariants = [...abTestModal.variants];
		updateAbTestModal({ variants: newVariants });
	};

	const addToVariants = () => {
		const newVariantValues = abTestModal.variants[0].values.map((value) => ({
			...value,
			value: '', // Reset the `value` field for the new variant
		}));

		const newVariant: ABTestVariant = {
			name: `Variant ${abTestModal.variants.length}`,
			uuid: '',
			values: newVariantValues,
		};

		const variantExists = abTestModal.variants.some((v) => v.name === newVariant.name);
		if (variantExists) {
			toast({
				title: `Variant name already exists: ${newVariant.name}`,
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
		} else {
			updateAbTestModal({ variants: [...abTestModal.variants, newVariant] });
		}
	};

	const deleteABTestModalVariant = (index: number) => {
		const newVariants = abTestModal.variants.filter((_, i) => i !== index);
		updateAbTestModal({ variants: newVariants });
	};

	const updateAbTestModalVariantRCValue = (variantIndex: number, rcIndex: number, test: Partial<RemoteConfigValue>) => {
		const variants = abTestModal.variants;
		const variant = variants[variantIndex];
		const rcValues = variant.values;
		const rcValue = rcValues[rcIndex];
		rcValues[rcIndex] = { ...rcValue, ...test };
		variant.values = rcValues;
		variants[variantIndex] = variant;
		updateAbTestModal({ variants });
	};

	const updateAbTestModalVariant = (index: number, test: Partial<ABTestVariant>) => {
		const variants = abTestModal.variants;
		const variant = variants[index];
		variants[index] = { ...variant, ...test };
		updateAbTestModal({ variants });
	};

	return (
		<FieldArray
			name="variants"
			render={(arrayHelpers) => (
				<FormControl mt={4} border={'0px'}>
					{abTestModal.variants.map((variant, index) => (
						<Stack key={index} paddingBottom={'50px'}>
							<HStack>
								<Field name={`variants.${index}.name`}>
									{({ field, form }: { field: any; form: any }) => (
										<FormControl isInvalid={form.errors.variants?.[index]?.name && form.touched.variants?.[index]?.name}>
											<Input
												{...field}
												border={'0px'}
												fontStyle={'bold'}
												value={variant.name}
												onChange={(e) => {
													updateAbTestModalVariant(index, { name: e.target.value });
													//field.onChange(e);
													if (abTestModal) {
														form.setFieldValue(`variants.${index}.name`, e.target.value);
													}
												}}
											/>
											<FormErrorMessage>{form.errors.variants?.[index]?.name}</FormErrorMessage>
										</FormControl>
									)}
								</Field>
								<Button
									rightIcon={<FaTrashAlt />}
									backgroundColor="transparent"
									_hover={{ bg: 'transparent' }}
									onClick={() => deleteABTestModalVariant(index)}
								/>
							</HStack>
							{index === 0 && (
								<Select
									value=""
									onChange={(e) => {
										addVariableToVariants(e.target.value);
									}}
								>
									<option value="">Select a variable</option>
									{rcValues.map((remoteConfigValue) => (
										<option key={remoteConfigValue.uid} value={remoteConfigValue.key}>
											{remoteConfigValue.key}
										</option>
									))}
								</Select>
							)}

							<VStack direction="row" mt={2} paddingTop={'15px'}>
								{variant.values.map((variable, rcIndex) => (
									<Field key={variable.key} name={`variants.${index}.values.${rcIndex}.key`}>
										{({ field, form }: { field: any; form: any }) => (
											<FormControl
												isInvalid={
													form.errors.variants?.[index]?.values?.[rcIndex]?.value &&
													form.touched.variants?.[index]?.values?.[rcIndex]?.value
												}
											>
												<InputGroup>
													<InputLeftAddon minWidth={'200px'}>{variable.key}</InputLeftAddon>
													<Textarea
														{...field}
														resize="None"
														size="sm"
														value={variable.value}
														onChange={(e) => {
															form.setFieldValue(`variants.${index}.values.${rcIndex}.value`, e.target.value);
															form.setFieldValue(`variants.${index}.values.${rcIndex}.key`, variable.key);
															updateAbTestModalVariantRCValue(index, rcIndex, { value: e.target.value });
															//field.onChange(e);
														}}
													/>
													<InputRightAddon>
														<Button
															rightIcon={<FaTrashAlt />}
															backgroundColor="transparent"
															_hover={{ bg: 'transparent' }}
															onClick={() => deleteVariableFromVariants(variable.key)}
														/>
													</InputRightAddon>
												</InputGroup>
												<FormErrorMessage>{form.errors.variants?.[index]?.values?.[rcIndex]?.key}</FormErrorMessage>
											</FormControl>
										)}
									</Field>
								))}
							</VStack>
							{abTestModal?.variants && index === abTestModal.variants.length - 1 && (
								<Field name="addVariant">
									{({ field, form }: { field: any; form: any }) => (
										<Button
											rightIcon={<LuTestTube2 />}
											colorScheme="blue"
											variant="outline"
											marginTop={'50px'}
											onClick={() => {
												addToVariants();
												form.setFieldValue(`variants.${index + 1}.name`, variant.name);
											}}
										>
											Add Variant
										</Button>
									)}
								</Field>
							)}
						</Stack>
					))}
				</FormControl>
			)}
		/>
	);
}

export default Variants;
